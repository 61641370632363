@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Avenir Next";
	font-weight: 400;
	src: url("/public/fonts/Avenir-Next-Font/Avenir-next-font.ttf");
}

body {
	font-family: "Avenir Next", "Montserrat", sans-serif !important;
}

.ant-form-large .ant-form-item-label > label {
	height: 0px !important;
	margin-top: 1rem !important;
	font-weight: 500;
	font-size: 12px;
	line-height: 19px;
	text-transform: capitalize;
	color: #ffffff;
}
.ant-form-large .ant-form-item-label > label::before {
	display: none !important;
}
